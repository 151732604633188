export const useStripe = () => {
  const config = useRuntimeConfig();

  async function getProduct(productId: string | number) {
    try {
      const { data, status, error, refresh } = await useFetch("/api/stripe/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          pragma: "no-cache",
          expires: "0",
        },
        body: JSON.stringify({
          request: "products.retrieve",
          value: productId,
        }),
      });

      if (!data || !data.value) {
        throw new Error(
          `Invalid response received for productId: ${productId}`,
        );
      }

      return data.value;
    } catch (error) {
      console.error("Error in getProduct function:", error);
      throw error;
    }
  }

  async function createCustomer(user: object) {
    if (!user.stripeCustomerId) {
      const { data, status, error, refresh } = await useFetch("/api/stripe/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          request: "customers.create",
          value: { email: user.email },
        }),
      });

      const customer = data.value;
      user.stripeCustomerId = customer.id;
    }

    return user;
  }

  async function getCustomerByEmail(email: string) {
    if (email) {
      const { data, status, error, refresh } = await useFetch("/api/stripe/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          request: "customers.search",
          value: {
            query: `email:'${email}'`,
          },
        }),
      });

      return data.value.length === 1 ? data.value[0] : null;
    }
  }

  async function createCheckoutSession(
    lineItems: array,
    user: object,
    options: {
      success_url: "https://localhost:3000/checkout-success?session_id={CHECKOUT_SESSION_ID}";
      return_url: "https://localhost:3000/checkout/return?session_id={CHECKOUT_SESSION_ID}";
      cancel_url: "https://localhost:3000/subscribe/cancel";
      ui_mode: null; // "embedded"
    },
  ) {
    const { data, status, error, refresh } = await useFetch("/api/stripe/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        request: "checkout.sessions.create",
        value: {
          billing_address_collection: "auto",
          line_items: lineItems,
          mode: "payment",
          ui_mode: options.ui_mode,
          ...(!options.ui_mode
            ? {
              success_url: options.success_url,
              cancel_url: options.cancel_url,
            }
            : {
              return_url: options.return_url,
            }),
          customer: user.stripeCustomerId,
          custom_fields: [
            {
              key: "name",
              label: {
                type: "custom",
                custom: "Your Name",
              },
              type: "text",
              optional: false,
            },
            {
              key: "company",
              label: {
                type: "custom",
                custom: "Company",
              },
              type: "text",
              optional: false,
            },
            // {
            //   key: "street",
            //   label: {
            //     type: "custom",
            //     custom: "Street",
            //   },
            //   type: "text",
            //   optional: false,
            // },
            // {
            //   key: "city",
            //   label: {
            //     type: "custom",
            //     custom: "City",
            //   },
            //   type: "text",
            //   optional: false,
            // },
            // {
            //   key: "zip_code",
            //   label: {
            //     type: "custom",
            //     custom: "ZIP Code",
            //   },
            //   type: "numeric",
            //   optional: false,
            // },
          ],
        },
      }),
    });
    const session = data.value;

    return {
      url: session.url,
      clientSecret: session.client_secret,
      user,
    };
  }
  function parsePrice(price: string | number) {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(price);
  }

  function priceValue(price: object) {
    return parseFloat(price.unit_amount / 100);
  }

  async function itemPrice(priceId: string | number) {
    const { data, status, error, refresh } = await useFetch("/api/stripe/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        request: "prices.retrieve",
        value: priceId,
      }),
    });

    const price = data.value;
    return { text: parsePrice(priceValue(price)), price };
  }

  return {
    createCustomer,
    getCustomerByEmail,
    createCheckoutSession,
    itemPrice,
    parsePrice,
    priceValue,
    getProduct,
  };
};
